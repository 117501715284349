import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faSnapchat, faWhatsapp, faGithub, faTelegram, faLinkedin, faThreads } from '@fortawesome/free-brands-svg-icons';


function App() {
  return (
    <div className="App">
      <h1>こんにちは</h1>
      <a href="https://twitter.com/afnansharyf/" title="tweet icons">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a href="https://t.me/afnansharyf/" title="whatsapp icons">
        <FontAwesomeIcon icon={faTelegram } />
      </a>
      <a href="https://www.instagram.com/afnansharyf/" title="instagram icons">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://www.threads.net/@afnansharyf/" title="whatsapp icons">
        <FontAwesomeIcon icon={faThreads} />
      </a>
      <a href="https://www.snapchat.com/add/afnansharyf" title="snapchat icons">
        <FontAwesomeIcon icon={faSnapchat} />
      </a>
      <a href="https://wa.me/qr/QUZR3J2MYTYTC1" title="whatsapp icons">
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a href="https://github.com/afnansharyf" title="whatsapp icons">
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a href="https://www.linkedin.com/in/afnan-sharyf-4ab21119b" title="whatsapp icons">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </div>
  );
}



export default App;
